// src/components/PromoBox.js
import React from 'react';
import './PromoBox.css';
import discovery from '../assets/bexy3.png';

const PromoBox = ({ setPage }) => {
  const handleRankingsClick = () => {
    // Set page to "rankings" when Rankings button is clicked
    setPage("community");
  };

  const handleLearnMoreClick = () => {
    // Set page to "learn" when Learn More button is clicked
    setPage("learn");
  };

  return (
    <div className="promo-box">
      <img src={discovery} alt="Promo Logo" className="promo-logo" />
      <div className="promo-divider"></div> {/* Vertical divider */}
      <div className="promo-content">
        <p>Welcome to the Bexy Discovery app, your destination for discovering active coins on Base! Get your project listed and allow your community to show their strength by voting. Let Bexy Discovery help you get the exposure you deserve.</p>
        <div className="promo-buttons">
          <button onClick={handleRankingsClick}>Communities</button>
          <button onClick={handleLearnMoreClick}>Learn More</button>
        </div>
      </div>
    </div>
  );
};

export default PromoBox;
