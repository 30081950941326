import React, { useState } from 'react';
import './Navbar.css';
import logo from '../assets/banner.png';


const Navbar = ({ setPage, currentPage }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleNavClick = (page, e) => {
    e.preventDefault();
    setPage(page);
    setIsOpen(false); // Close menu after selection
  };

  return (
    <nav className="navbar">
      <img src={logo} alt="Logo" className="logo" />

      <button className="hamburger-menu" onClick={toggleMenu} aria-label="Toggle menu">
        &#9776;
      </button>

      <div className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
        <a href="#$GEMS" onClick={(e) => handleNavClick("gems", e)} className={currentPage === "gems" ? "active" : ""}>
          - $BEXY
        </a>
        <a href="#gemini" onClick={(e) => handleNavClick("gpt", e)} className={currentPage === "gpt" ? "active" : ""}>
          - BexyGPT
        </a>
        <a href="#learn" onClick={(e) => handleNavClick("learn", e)} className={currentPage === "learn" ? "active" : ""}>
          - Learn More
        </a>
        <a href="#all" onClick={(e) => handleNavClick("all", e)} className={currentPage === "all" ? "active" : ""}>
          - All Listings
        </a>
        <a href="#community" onClick={(e) => handleNavClick("community", e)} className={currentPage === "community" ? "active" : ""}>
          - Rankings
        </a>
        <a href="#featured" onClick={(e) => handleNavClick("featured", e)} className={currentPage === "featured" ? "active" : ""}>
          - Bexy List
        </a>
        <a href="https://x.com/basegems_io" target="_blank" rel="noopener noreferrer">
          - Twitter / X
        </a> 
      </div>
    </nav>
  );
};

export default Navbar;
