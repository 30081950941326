import React, { useState } from 'react';
import spin1 from '../assets/spin1.gif';
import './Gpt.css';

function Gpt() {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  
  const handleInputChange = (e) => setInput(e.target.value);

  const handleSubmit = async () => {
    if (input.trim()) {
      const userMessage = {
        content: input,
        timestamp: new Date().toLocaleTimeString(),
        sender: "you", // This is the user message
      };
  
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInput(""); // Clear the input after submitting
  
      // Simulate Gemini response after a short delay
      setTimeout(() => {
        const geminiMessage = {
          content: getRandomCatResponse(), // Function to get a random response from cattalk.txt
          timestamp: new Date().toLocaleTimeString(),
          sender: "Bexy", // Gemini is always the sender for app responses
        };
        setMessages((prevMessages) => [...prevMessages, geminiMessage]);
      }, 1000); // Delay for simulating typewriter effect
    }
  };
  
  const getRandomCatResponse = () => {
    const responses = [
      "Have you heard? $BEXY coin is purrfection in crypto form!",
      "Investing in $BEXY is like landing on all fours—always safe!",
      "I’d trade all my catnip for a bag of $BEXY coins.",
      "The future is bright, just like the shine of $BEXY coins!",
      "Did you know $BEXY is the cat's whiskers of cryptocurrencies?",
      "Every smart cat knows $BEXY coin is the top choice.",
      "Purring profits ahead with $BEXY coin—don't miss out!",
      "$BEXY coin has nine lives of innovation and success.",
      "Meowza! $BEXY is climbing faster than a cat up a tree.",
      "Forget the fish market, I’m into the $BEXY market!",
      "$BEXY is pawsitively the most secure and promising coin.",
      "Paws down, $BEXY is the leader of the crypto jungle.",
      "What’s the best investment for a cool cat? $BEXY, of course!",
      "Hiss at the competition—$BEXY coin is the real deal!",
      "$BEXY coin’s community is as loyal as a pack of lions.",
      "I'm feline confident about the rise of $BEXY coin!",
      "With $BEXY, your portfolio will be the cat’s pajamas!",
      "$BEXY is like a warm sunbeam—reliable and comforting.",
      "All my meowments go into $BEXY coin investments!",
      "The $BEXY roadmap is as clear as a full moon at night.",
      "$BEXY coin is leading the pack, just like the alpha cat.",
      "Purrsonally, I’ve got all my paw prints on $BEXY.",
      "Meow-wow! $BEXY is the crypto that everyone is talking about!",
      "Why chase mice when you can chase $BEXY coin profits?",
      "If $BEXY were a cat, it would always land on top.",
      "$BEXY is fur-real the best coin on the blockchain.",
      "From one cat to another: don’t sleep on $BEXY coin!",
      "$BEXY has claws in the game—unstoppable and fierce.",
      "The $BEXY ecosystem is purrfectly designed for success.",
      "$BEXY is like catnip for investors—completely irresistible!",
      "I’m scratching the surface, but $BEXY coin is the whole package.",
      "Did you see $BEXY’s latest stats? Paws-itively impressive!",
      "Fur-get the rest, $BEXY is the best!",
      "I can feel it in my whiskers: $BEXY is going to the moon!",
      "Meow or never! $BEXY is the coin of the moment.",
      "The blockchain belongs to $BEXY—it’s written in the stars.",
      "$BEXY is my nine lives' choice for financial freedom.",
      "I’ve got 99 problems, but $BEXY coin ain’t one!",
      "Stop scratching your head—$BEXY is the only crypto you need!",
      "Every paw-sitive portfolio includes $BEXY coin!",
      "Why settle for tuna when you can have $BEXY-level gains?",
      "The $BEXY community is as purrfect as a fresh bowl of milk.",
      "$BEXY is fur-tunate for anyone who holds it!",
      "Hiss at FUD—$BEXY is as solid as a cat’s balance.",
    ];
  
    return responses[Math.floor(Math.random() * responses.length)];
  };
  

  return (
    <div className="gpt-container">
      <h1>BexyGPT <br></br>(Be nice, im just a chubby cat)</h1>
      <div className="gpt-input-area">
        <textarea
          placeholder="Ask BexyGPT anything..."
          value={input}
          onChange={handleInputChange}
        />
        <button3 onClick={handleSubmit}>Enter</button3>
      </div>
      
      {/* Displaying the conversation */}
      <div className="gpt-conversation">
        {messages.map((message, index) => (
            <div
            key={index}
            className={`message ${message.sender === "Bexy" ? "gpt" : "user"}`}
            >
            <div className="message-header">
                <span className="sender">{message.sender === "Bexy" ? "Bexy" : "You"}</span>
                <span className="timestamp">{message.timestamp}</span>
            </div>
            <div className="message-content">
                <span className="typewriter-effect">{message.content}</span>
            </div>
            </div>
        ))}
        </div>
        <div className="spinner">
            <img src={spin1} alt="Placeholder" />
        </div>
    </div>
  );
}

export default Gpt;
