import React, { useEffect, useState } from 'react';
import './Gems.css';
import base3 from '../assets/banner.png';
import spin from '../assets/spinG.gif';
import logo from '../assets/geminiLogo.png';
import dexs from '../assets/dexs.png';
import dt from '../assets/dt.png'; 
import us from '../assets/us.png';
import x from '../assets/x.png';

const cards = [
  {
    id: 1, image: logo, header: "Bexynomics",
    content: [
      "0% Taxes / LP Burned (Based)",
      "1,000,000,000 Supply (Based)",
      "Contract Renounced (Based)",
    ],
  },
  {
    id: 2, image: logo, header: "Join the community",
    content: [
      "Create and fund a Base compatible wallet with Base ETH",
      "Swap Base $ETH for $BEXY on your favorite dex",
      "CA: 0xCOMINGSOONBEXY",
    ],
  },
  {
    id: 3, image: logo, header: "Bexy Discovery",
    content: [
      "Bexy holders vote on new Bexy Discovery listings weekly",
      "Users can upvote their favorite Base coins once per day",
      "The App lets users find community voted gems on Base, all while showcasing the strength of our own Base gem, $BEXY.",
    ],
  },
];

function Gems() {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const countdownDate = 1734807600 * 1000; // Convert epoch to milliseconds
    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      if (distance > 0) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      }
    };

    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, []);

  const nextCard = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex + 1) % cards.length);
  };

  const previousCard = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
  };

  const activeCard = cards[currentCardIndex];

  const copyToClipboard = () => {
    navigator.clipboard.writeText("0xCOMINGSOONBEXY");
    alert("Contract Address copied to clipboard!");
  };

  return (
    <div className="gems-container">
      {/* Top Section */}
      <div className="top-section">
        <div className="text-column">
          <img src={base3} alt="Placeholder" />
          <p>
            $BEXY on Base is a meme coin marking the launch of Bexy Coin Discovery. A tool intended to 
            scale along side $BEXY coin, the coin discovery will launch with 20 top Base meme coins. 
            Each week, $BEXY holders will have the chance to vote for 3 new projects to be listed on 
            our Base Discovery tool. $BEXY is the foundation for our growing ecosystem built on Base.
            Join the journey today, BEXY the Based Cat to millions!
          </p>
          <div className="countdown-container">
            <span className="countdown-label">$BEXY Launch:</span>
            <span className="countdown-timer">
              {`${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`}
            </span>
          </div>
          <div className="countdown-container">
            <div className="swap-icons">
              <a href="" target="_blank" rel="noopener noreferrer">
                <img src={x} alt="X" />
              </a>
              <a href="" target="_blank" rel="noopener noreferrer">
                <img src={dexs} alt="DexScreener" />
              </a>
              <a href="" target="_blank" rel="noopener noreferrer">
                <img src={dt} alt="DexTools" />
              </a>
              <a href="" target="_blank" rel="noopener noreferrer">
                <img src={us} alt="UniSwap" />
              </a>
              <button className="copy-button" onClick={copyToClipboard}>
                Copy CA
              </button>
            </div>
          </div>
        </div>
        <div className="image-column">
          <img src={spin} alt="Placeholder" />
        </div>
      </div>

      {/* Card Section */}
      <div className="card-slider">
        <div className="card">
          <img src={activeCard.image} alt="Card Icon" />
          <div className="card-details">
            <h1 className="card-header">{activeCard.header}</h1>
            <ul className="bullet-points">
              {activeCard.content.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div className="arrows-container">
            <button className="arrow left-arrow" onClick={previousCard}>
              &#8592;
            </button>
            <button className="arrow right-arrow" onClick={nextCard}>
              &#8594;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gems;
